import {abstractApiDetail} from "../reponse/abstractAPI.interface";
import {useEffect, useState} from 'react';
import {abstract} from "../reponse/abstract";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseLevel = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getLevel()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const ResponsePrasentationType = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPrasentationType()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const ResponseSession = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getSession()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}



export const ResponseAbstract = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseAbstractByUser = (limit:any,searchValue:any,userid:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPostsUser(limit,searchValue,userid)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue,userid])


    return isError ? (posts ) : (posts);

}

export const ResponseAbstractByHost = (limit:any,searchValue:any,userid:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPostsHost(limit,searchValue,userid)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue,userid])


    return isError ? (posts ) : (posts);

}

export const ResponseAbstractSingle = (id:any,author?:any,keywords?:any,affiliate?:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);

                    if(affiliate) {
                        affiliate(data[0].affiliates.length -1)
                    }
                    if(keywords) {
                       keywords(data[0].tags.length - 1)
                    }
                    if(author) {
                        author(data[0].authors.length - 1)
                    }

                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

/**
 * Daten ausfüllen für die registration vorausfüllen
 * @param limit
 * @param searchValue
 * @param userid
 * @constructor
 */
export const ResponseAbstractByUserSingle = (limit:any,searchValue:any,userid:any) => {
    const [posts, setPosts] = useState<abstractApiDetail>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPostsUser(limit,searchValue,userid)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }

                    let NewData = data[1]

                    setPosts(NewData);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue,userid])


    return isError ? (posts ) : (posts);

}