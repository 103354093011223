import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import {
    ResponseLevel,
    ResponsePrasentationType,
    ResponseSession,
    ResponseAbstractSingle,
    ResponseHasUser, ResponseACountry, ResponsePronouns, ResponseParticipantType
} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {UniCol, UniForm, UniRow} from "../../../../../@WUM/core/Elements";
import {getToken} from "../../../../../@WUM/core/Function/index"
import React, {useState} from "react";
import {CreateUserIS} from "../helper/HasUser.helper";
import Validator from "../../../../../@WUM/core/component/const/Validator";
import FieldNamesPersonal from "../elements/FieldNamesPersonal";
import FieldNamesNamebadge from "../elements/FieldNamesNamebadge";
import FieldNamesRegistrationDetails from "../elements/FieldNamesRegistrationDetails";
import {discountCodeMap} from "../helper/discountCode.map";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {ScientistIcon, UniversityIcon} from "../../../../../@WUM/templates/Library";
import {PersonalInfo} from "../elements/personalInfo";
import {InstitutionalInfo} from "../elements/InstitutionalInfo";
import {NameBadge} from "../elements/NameBadge";
import {RegistrationDetails} from "../elements/RegistrationDetails";
import {Survey} from "../elements/Survey";
import {PaymentForm} from "../../../../../@WUM/core/stripe/PaymentForm";

const RegistrationDetail = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const {id} = useParams<{ id: string }>();
    const HasUser = ResponseHasUser(id,'2024');




    const [part, setPart] = useState('namebadge')
    const [RegistrationID, setRegistrationID] = useState(0)
    const UserDataOld = CreateUserIS(HasUser,setRegistrationID,RegistrationID)
    // prepares holder which contain all countries for select fields
    let Country = ResponseACountry() /*<-- Einbinden des Ergebnisses */
    let CountryHolder = []; /* <-- Bildung des Holders */
    if (Country !== true && typeof Country !== "boolean" && Country?.length > 0) { /* <-- Sicherheitsabfrage ob Route durch ist */
        for (let us = 0; us < Country?.length; us++) { /* <-- Durchgang durch ergebniss  */
            let text = Country[us].valueDE
            CountryHolder.push( /* <-- Push ins Array des Fetches */
                {value:Country[us].id,text:text}
            )
        }
    }
    // prepares holder containing pronouns
    let Pronouns = ResponsePronouns() /*<-- Einbinden des Ergebnisses */
    let PronounsHolder:any = []; /* <-- Bildung des Holders */
    if (Pronouns !== true && typeof Pronouns !== "boolean" && Pronouns?.length > 0) { /* <-- Sicherheitsabfrage ob Route durch ist */
        for (let us = 0; us < Pronouns?.length; us++) { /* <-- Durchgang durch ergebniss  */
            PronounsHolder.push( /* <-- Push ins Array des Fetches */
                {value:Pronouns[us].id,text:Pronouns[us].pronoun}
            )
        }
    }

    // prepares holder containing participant types
    let ParticipantType = ResponseParticipantType()
    let ResponseParticipantTypeHolder:any = [];
    if (ParticipantType !== true && typeof ParticipantType !== "boolean" && ParticipantType?.length > 0) {
        for (let us = 0; us < ParticipantType?.length; us++) {
            let value = ParticipantType[us].id
            let text = ParticipantType[us].participant
            ResponseParticipantTypeHolder.push(
                {value:value,text:text}
            )
        }
    }

    /**
     * @typedef {string} TabKey - A type representing the possible tab keys.
     *
     * Initializes the state variable for the active tab key using the useState hook.
     *
     * @returns {Array} An array containing the current active tab key and a function to update it.
     */
    const [tabActiveKey, setTabActiveKey] = useState('personal');

    // Debug for next Tab
    //console.log(tabActiveKey)
    //console.log(part)
    /**
     * Handles unlocking a tab based on the provided tab name.
     *
     * @param {string} tab - The name of the tab to unlock.
     */
    const handleTabUnlock = (tab:string,isBack?:boolean,e?:any) => {
        e?.preventDefault()
        if (tabActiveKey === 'personal') {
            if( isBack !== true) {
                if (Validator(FieldNamesPersonal, Store)) {
                    setTabActiveKey(tab);
                    send(e);
                    setPart('registrationDetails')
                }
            } else if(isBack) {
                setTabActiveKey(tab);
                setPart('namebadge')
            }
        } else if (tabActiveKey === 'namebadge') {
            if( isBack !== true) {
                if (Validator(FieldNamesNamebadge, Store)) {
                    setTabActiveKey(tab);
                    send(e);
                    setPart('survey')
                }
            } else if(isBack) {
                setTabActiveKey(tab);
                setPart('personal')
            }
        } else if (tabActiveKey === 'registrationDetails') {
            if( isBack !== true) {
                if (Validator(FieldNamesRegistrationDetails, Store)) {
                    setTabActiveKey(tab);
                    send(e);
                    setPart('stripe')
                }
            } else if(isBack) {
                setTabActiveKey(tab);
                setPart('namebadge')
            }
        } else if (tabActiveKey === 'survey') {

            let code = document.getElementById('registerCode') as HTMLInputElement;
            let registrationV = document.getElementById('RegistrationVariant') as HTMLSelectElement;
            let brige = 0;
            for(let dcm=0;dcm < discountCodeMap.length;dcm++){
                let mapping = discountCodeMap[dcm]
                if (mapping.role == registrationV.value) {
                    if(mapping.code == code.value) {
                        brige++;
                    }
                }
            }
            if(brige === 0) {
                if( isBack !== true) {
                    if (Validator(FieldNamesNamebadge, Store)) {
                        setTabActiveKey(tab);
                        send(e);
                        setPart('stripe')
                    }
                } else if(isBack) {
                    setTabActiveKey(tab);
                    setPart('registrationDetails')
                }
            } else {
                // Tue was auch immer gregor will, nur ein beispiel bitte nach bedarf und Lust abändern. Nicht Militärisch dran halten
                if (Validator(FieldNamesNamebadge, Store)) {
                    setTabActiveKey(tab);
                    send(e);
                    setPart('end')
                }
            }

        } else if (tabActiveKey === 'stripe') {
            if( isBack !== true) {
                if (Validator(FieldNamesNamebadge, Store)) {
                    setTabActiveKey(tab);
                    send(e);
                }
            } else if(isBack) {
                setTabActiveKey(tab);
                setPart('registrationDetails')
            }
        } else {
            setTabActiveKey(tab);
        }
    };

    const send = (e: any) => {
        const form = e.currentTarget
        e.preventDefault() /* <-- Unterbrechen des normalen Form Event und Reloads */
        let Data = init2(form) /* <-- Einbinden der Init2 methode die automatisch alle Form Felder mit name parse */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());/* <-- Header mit Bearer Token **/

        var raw = JSON.stringify(
            {
                "confParticipantType_id": (Data.registrationVariant !== undefined && Data.registrationVariant !== ''  ? 1 : 0),
                "confSocialEvents_id": (Data.confSocialEvents_id !== undefined ? Data.confSocialEvents_id : 0),
                "acceptDataStorage":  (Data.acceptDataStorage !== undefined && Data.acceptDataStorage === 'on' ? 1 : 0 ),
                "acceptPhoto": (Data.acceptPhoto !== undefined && Data.acceptPhoto === 'on' ? 1 : 0 ),
                "year": 2024, /* später */
                "icebreaker":  (Data.icebreaker !== undefined && Data.icebreaker === 'on' ? 1 : 0 ),
                "postersession": (Data.postersession !== undefined && Data.postersession === 'on' ? 1 : 0 ),
                "sciencespeedmeeting": (Data.sciencespeedmeeting !== undefined && Data.sciencespeedmeeting === 'on' ? 1 : 0 ),
                "sciencecommunicationevening":  (Data.sciencecommunicationevening !== undefined && Data.sciencecommunicationevening === 'on' ? 1 : 0 ),
                "postconferenceparty":  (Data.postconferenceparty !== undefined && Data.postconferenceparty === 'on' ? 1 : 0 ),
                "personal_details": {
                    "id": Data.personal_details_id,
                    "title": Data.title,
                    "middle_name": Data.MiddleName,
                    "street": Data.street,
                    "streetNumber": Data.streetNumber,
                    "zip": Data.zip,
                    "first_name": Data.first_name,
                    "last_name":  Data.last_name,
                    "cityPersonal": Data.cityPersonal,
                    "countryAdressPersonal": Data.CountyAdressPersonal,
                },
                "institutional_address": {
                    /*"id": Data.institutional_address_id,*/
                    "nameAff": Data.nameAff,
                    "streetAff": Data.streetAff,
                    "numberAff": Data.numberAff,
                    "zipAff": Data.zipAff,
                    "cityInstitut": Data.cityInstitut,
                    "countryInstitut": Data.CountryAdressInstitutional,
                    "vatInstitut": Data.vatInstitut,
                    "InvoicePersonal": Data.InvoicePersonal
                },
                "name_badge": {
                    /*"id": Data.name_badge_id,*/
                    "nameBadgeName": Data.nameBadgeName,
                    "nameBadgeAff": Data.nameBadgeAff,
                    "pronoun_id": Data.nameBadgePronoun,
                    "nameBadgePronounOther": Data.nameBadgePronounOther,
                    "researchInt": Data.researchInt,
                }
            }
        );


        var requestOptions = {
            method: 'POST', /* <-- gewählte Methode */
            headers: myHeaders, /* <-- Anbindung Headers */
            body: raw, /* <-- Anbindung der oben gebildeten Objeckt an den Body */
        };

        fetch(ApiURL() +"Conf/registration/details/"+RegistrationID+"/ ", requestOptions) /* <-- Route mittels APIURL und endpoint sowie pbergabe der RequestOptions mit method, Header und Body */
            .then(response => response.json())
            .then(result => {SuccesRegistrion(result)}) /* <-- Funktion die bei Success ausgeführt wird. Obacht hier ein beispiel kann auch eine andere eigene oder oder hin. wen keine Funktion einfach console.log(result) rein */
            .catch(error => console.log(error));/* <-- Funktion die bei einen error fall ausgelöst wird selbiges wie oben bei keiner funktion einfach console.log(error) rein */

    }

    const SuccesRegistrion = (result:any) =>  {
        if(RegistrationID === 0) {
            setRegistrationID(result.id)
        }
    }



    if (1 === 1) {
        // let country: any = [];
        // for (let c = 0; c < fetchAllCountries.length; c++) {
        //     const customer = fetchAllCountries[c];
        //
        //     fetchAllCountries.push({
        //         value: customer.id,
        //         text: customer.firma,
        //     });
        // }

        localStorage.setItem("priceWebsite", JSON.stringify(2500));
        localStorage.setItem("priceHosting", JSON.stringify(0));
        localStorage.setItem("priceVoucher", JSON.stringify(0));
        const User = {
            email: "gschalm@wum-solution.tech",
            firstname: "Gregor",
            lastname: "Schalm"
        };
        localStorage.setItem("User", JSON.stringify(User));



        if (Country !== true && typeof Country !== "boolean" && Country?.length > 0 && UserDataOld !== undefined &&
            Pronouns !== true && typeof Pronouns !== "boolean" && Pronouns?.length > 0 &&
            HasUser !== undefined && HasUser.length > 0 &&
            process.env.REACT_APP_REGISTRATION_ACTIVE !== undefined && process.env.REACT_APP_REGISTRATION_ACTIVE === 'True') {
            return (
                <>
                    <UniForm validated={false} Submit={(e) => handleTabUnlock(part, false, e)}
                             className="p-3"

                    >
                        {/*<div
                            className={props.value !== 'visibleNon' ? "Order pb-5 bpage" : "Order backendStep noneSidebar"}
                            style={props.value === 'visibleNon' ? {backgroundImage: ''} : {backgroundColor: '#ffffff'}}>
                            {props.value === 'visibleNon' ?
                                <h1>Wähle deine Zahlungsmöglichkeit</h1> :
                                <h1>Wähle deine Zahlungsmöglichkeit</h1>}
                            <PaymentForm/>

                        </div>*/}

                        <Tabs
                            defaultActiveKey="personal"
                            id="registration-tabs"
                            activeKey={tabActiveKey}
                        >
                            <Tab eventKey="personal" title="Personal Details">

                                <UniRow>
                                    <UniCol class={"d-lg-none d-flex mb-5"}>

                                        <div className={"mx-auto"} style={{width: '30%'}}>
                                            <ScientistIcon/>
                                        </div>
                                        <div className={"d-flex"}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet.
                                        </div>
                                    </UniCol>
                                    <UniCol xxl={6} xl={7} lg={8} md={12}>
                                        <PersonalInfo Store={Store} Country={CountryHolder} UserDataOld={UserDataOld}/>
                                    </UniCol>
                                    <UniCol class={"d-lg-block d-none my-auto"}>
                                        <div className={"mx-auto mb-4"} style={{width: '40%'}}>
                                            <ScientistIcon/>
                                        </div>
                                        {/*<div className={"d-flex"}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet.
                                        </div>*/}
                                    </UniCol>
                                </UniRow>

                                <UniRow>
                                    <UniCol class={"my-auto"}>
                                        <div className={"mx-auto"} style={{width: '30%'}}>
                                            <UniversityIcon/>
                                        </div>
                                        {/*<div className={"d-flex"}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet.
                                        </div>*/}

                                    </UniCol>
                                    <UniCol xxl={6} xl={7} lg={8} md={12}>
                                        <InstitutionalInfo Store={Store} Country={CountryHolder} UserDataOld={UserDataOld}/>
                                    </UniCol>
                                </UniRow>
                                <div className={'mt-3'}>
                                    <Button type={'submit'} className={'float-end'}>Continue</Button>
                                    <br style={{clear: 'both'}}/>
                                </div>

                            </Tab>
                            <Tab eventKey="namebadge" title="Specify your name badge">
                                <NameBadge Store={Store} Pronouns={PronounsHolder} UserDataOld={UserDataOld}/>
                                <div className={'mt-3'}>
                                    <Button onClick={() => handleTabUnlock('personal', true)}
                                            className={'float-start'}>Back</Button>
                                    <Button type={'submit'} className={'float-end'}>Continue</Button>
                                    <br style={{clear: 'both'}}/>
                                </div>
                            </Tab>
                            <Tab eventKey="registrationDetails" title="Registration details">

                                <RegistrationDetails Store={Store} UserDataOld={UserDataOld} participantTypes={ResponseParticipantTypeHolder}/>
                                <div className={'mt-3'}>
                                    <Button onClick={() => handleTabUnlock('namebadge', true)}
                                            className={'float-start'}>Back</Button>
                                    <Button type={'submit'} className={'float-end'}>Continue</Button>
                                    <br style={{clear: 'both'}}/>
                                </div>
                            </Tab>
                            <Tab eventKey="survey" title="Survey">
                                <Survey Store={Store}/>
                                <div className={'mt-3'}>
                                    <Button onClick={() => handleTabUnlock('registrationDetails', true)}
                                            className={'float-start'}>Back</Button>
                                    <Button type={'submit'} className={'float-end'}>Continue</Button>
                                    <br style={{clear: 'both'}}/>
                                </div>
                            </Tab>
                            <Tab eventKey="stripe" title="Payment">
                                <div
                                    className={props.value !== 'visibleNon' ? "Order pb-5 bpage" : "Order backendStep noneSidebar"}
                                    style={props.value === 'visibleNon' ? {backgroundImage: ''} : {backgroundColor: '#ffffff'}}>
                                    {props.value === 'visibleNon' ?
                                        <img className="wkLogo" src={'/Logo_Webkom24.png'}
                                             alt={'Webkommunikation24 GmbH'}/> : ''}
                                    {props.value === 'visibleNon' ?
                                        <h1>Wähle deine Zahlungsmöglichkeit</h1> :
                                        <h1>Wähle deine Zahlungsmöglichkeit</h1>}
                                    <PaymentForm backUrl={'/registration/overview/1'} StripeMethod={['card']} id={RegistrationID} class={"WUM\\conferenceBundle\\Entity\\confRegistrationDetails"}/> {/* <-- Dies holt die Zahlungsmethoden ab und leitet auf STripe weiter */}

                                </div>
                                <Button onClick={() => handleTabUnlock('survey', true)}
                                        className={'float-start'}>Back</Button>

                            </Tab>
                            <Tab eventKey="end" title="end">
                                <p>
                                    Danke
                                </p>
                                <Button onClick={() => handleTabUnlock('survey', true)}
                                        className={'float-start'}>Back</Button>

                            </Tab>
                        </Tabs>
                    </UniForm>

                </>)
        } else {
            return (
                <>
                    <div className={"m-3"}>
                        <h1>Registration is closed!</h1>
                        <p>Hi there!</p>
                        <p>Registration is closed at the moment
                        </p>
                    </div>
                </>
            );
        }
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default RegistrationDetail;
