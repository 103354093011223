import {PDFViewer} from "@react-pdf/renderer";
import InvoicePDFController from "../../../components/Elements/pdf/Controller/InvoiceController";
import {ResponseInvoiceDetail, ResponseParticipantInfo} from "../API/Response/profil";
import React, {useState} from "react";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import QrController from "../../../../@WUM/core/QrCode/Controller/QrController";
import {UniButton, UniCol, UniRow} from "../../../../@WUM/core/Elements";
import {useParams} from "react-router-dom";
import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";
import {sendStorno} from "../API/Request/Storno";
import {StornoInterface} from "../API/Request/storno.interface";


const InvoiceDetail = (props:{value:any}) => {

    const Store = useSelector((state: any) => state)

    const {id} = useParams<{ id: string }>();

    const UserId = localStorage.getItem("userid")


    const InvoiceDetail = ResponseInvoiceDetail(Number(id))

    const ParticipantInfo = ResponseParticipantInfo(Number(UserId))

    const handleButtonClick = () => {
        const props: StornoInterface = { id: id };
        sendStorno(props);
    };



    if (InvoiceDetail !== true && typeof InvoiceDetail !== "boolean" && InvoiceDetail !== undefined && ParticipantInfo !== true && typeof ParticipantInfo !== "boolean" && ParticipantInfo !== undefined) {

        // construction of the link to register for lin
        const participantId = ParticipantInfo['id'];
        const routeStart = 'checkin/'
        const user = ParticipantInfo["user"]
        const string = UserId + user.first_name + user.last_name

        // the trailing = have to be removed to avoid issues with url. They don't matter in base64.
        const hash = btoa(string).replace(/=$/g, '');
        const url = ApiRoot() + routeStart + '24/' + participantId + "/" + hash

        console.log(url)


        return (
            <>
                <div id={'download'}/><div/>
                <UniRow >
                    <PDFViewer
                        width={"auto"}
                        height={"750px"}
                        className={"col-8"}
                    >
                        <InvoicePDFController response={InvoiceDetail}/>
                    </PDFViewer>
                    <UniCol>
                        <QrController url={url} width={200} height={200}/>
                        {props.value === "manage" ?
                            <UniCol md={12} class={"mt-5"}>
                                <UniButton onClick={handleButtonClick}>
                                    Send storno
                                </UniButton>
                            </UniCol>
                                :
                                <></>}

                    </UniCol>

                </UniRow>



            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default InvoiceDetail;
