import BadgePDFView from "../View/BadgePDFView";
import {BadgePDFControllerModel} from "../Model/BadgePDFControllerModel";

const BadgePDFController = (props: BadgePDFControllerModel) => {


    return (
        <BadgePDFView
            name={props.name}
            pronoun={props.pronoun}
            affiliation={props.affiliation}
            interests={props.interests}
        />
    )
};

export default BadgePDFController
