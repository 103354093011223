import React from "react";
import OverviewProfil from "./modules/overview";
import CertifcationProfil from "./modules/certifcation";
import InvoiceOverview from "./modules/invoiceOverview";
import InvoiceDetail from "./modules/invoiceDetail";
import {useSelector} from "react-redux";
import InvoiceOverviewUser from "./modules/invoiceOverviewUser";


export const ProfilPagesConfig = (value:any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
                {
                    path: '/invoice/overview-user/:page',
                    elements: <InvoiceOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/detail/:id',
                    elements: <InvoiceDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/certifaction/profil/:page',
                    elements: <CertifcationProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        case 'icymare':
            return [
                {
                    path: '/invoice/overview-user/:page',
                    elements: <InvoiceOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/overview/:page',
                    elements: <InvoiceOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/profil/:page',
                    elements: <OverviewProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/profil/:page',
                    elements: <OverviewProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/overview/:page',
                    elements: <InvoiceOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/detail/:id',
                    elements: <InvoiceDetail value={"manage"}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/certifaction/profil/:page',
                    elements: <CertifcationProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        case 'superadmin':
            return [
                {
                    path: '/invoice/profil/:page',
                    elements: <OverviewProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/overview-user/:page',
                    elements: <InvoiceOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/overview/:page',
                    elements: <InvoiceOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/invoice/detail/:id',
                    elements: <InvoiceDetail value={"manage"}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/certifaction/profil/:page',
                    elements: <CertifcationProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        default:
            return [
                {
                    path: '/invoice/profil/:page',
                    elements: <OverviewProfil value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
    ]
    }
}

    export const ProfilMenuConfig = (value: any) => {
        const Store = useSelector((state: any) => state)
        let Role = Store.user.roles
        switch (Role) {
            case 'user':
                return [
                    {
                        name: 'Invoice',
                        route: '/invoice/overview-user/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }, {
                        name: 'certificate',
                        route: '/certifaction/profil/1',
                        icon: 'receipt_long',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    },
                ]
            case 'host':
                return [
                    {
                        name: 'Invoice',
                        route: '/invoice/overview-user/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }, {
                        name: 'certificate',
                        route: '/certifaction/profil/1',
                        icon: 'receipt_long',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    },
                ]
            case 'icymare':
                return [
                    {
                        name: 'Invoice',
                        route: '/invoice/overview-user/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }, {
                        name: 'Invoice',
                        route: '/invoice/overview/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }, {
                        name: 'certificate',
                        route: '/certifaction/profil/1',
                        icon: 'receipt_long',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    },
                ]
            case 'superadmin':

                return [
                    {
                        name: 'Invoice',
                        route: '/invoice/overview-user/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }, {
                        name: 'Invoice',
                        route: '/invoice/overview/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }, {
                        name: 'certificate',
                        route: '/certifaction/profil/1',
                        icon: 'receipt_long',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    },
                ]
            default:
                return [
                    {
                        name: 'certificate',
                        route: '/certifaction/profil/1',
                        icon: 'receipt_long',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }
                ]
        }
    }

    export const ProfilMainMenuConfig = (value: any) => {
        return [
            {
                header: 'User profile',
                icon: 'person',
                menuitem: [
                    ...ProfilMenuConfig(value)
                ],
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            },
        ];
    }

    export const Profilsettings = () => [
        {
            title: 'Invoice',
            href: '/invoice/profil/1',
            undertitle: 'Profil',
            icon: 'payments',
            text: 'Profil'
        },
        {
            title: 'Certificate',
            href: '/certifaction/profil/1',
            undertitle: 'Profil',
            icon: 'receipt_long',
            text: 'Profil'
        }
    ]