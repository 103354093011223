import {useNavigate, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector} from "react-redux";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ResponseAuthor, ResponseSessionSingle} from "../../API/response/session";
import Button from "react-bootstrap/Button";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
const SessionDetail = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    const response = ResponseSessionSingle(id);
    const author = ResponseAuthor();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)
    const send = (e:any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let raw = JSON.stringify(value);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"Conf/Sessions/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4}))
            .catch(error => Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4}));
    }
    if(response.length > 0 && author.length > 0) {

        return (<>
            <Row className={''}>
                <Row>
                    <Col xs={4} md={4} lg={4} style={{background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',color:'white',maxHeight:'840px',minHeight: '815px'}}>
                        <br />
                        <p className={'text-center'}><img src={'/assets/ICYMARELogo.jpg'} /></p>
                        <br />
                        <h2>Abstract Submission ICYMARE 2023 Oldenburg</h2>
                        <p> <img src={"/assets/ICYMARE-2023-OLDENBURG_Banner.jpg"} /></p>
                        <br />
                        <p>Please carefully fill out this form to submit your abstract to ICYMARE 2024 Bremen. Once your abstract is accepted, please register through MyICYMARE platform to secure your presentation. We will keep you noted when it will be available.</p>
                    </Col>
                    <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',minHeight: '815px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e)}>
                        <Row>
                            <Col>
                                <h1>Session</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" name={'title'} placeholder="Enter Title" defaultValue={response[0].title}/>
                                </Form.Group>
                            </Col>
                        </Row>
                            <Row>

                            <Col>
                                <Form.Group className="mb-3" controlId="year">
                                    <Form.Label>year</Form.Label>
                                    <Form.Control type="text" name={'year'} min="1900" max="2099" placeholder="Enter year" defaultValue={response[0].year}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <FetchSelect Data={author} id={'id'} controlId={'userId'} name={'userId'}  value={'first_name'} value2={'last_name'} Uid={response[0].userId} label={'Session Host'} />
                        <br />
                        </Row>
                        <br />
                        <Row>

                            <Col>
                                <Form.Group className="mb-3" controlId="start">
                                    <Form.Label>start time</Form.Label>
                                    <Form.Control type="datetime-local" name={'start'} placeholder="Enter year" defaultValue={response[0].start}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="end">
                                    <Form.Label>end time</Form.Label>
                                    <Form.Control type="datetime-local" name={'end'} placeholder="Enter year" defaultValue={response[0].end}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <br /><br />
                            <Col>
                                {back}
                        <Button variant="primary" className={'float-end'} type="submit"
                                style={{background: '#307593 0% 0% no-repeat padding-box',
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: '34px',
                                    marginBottom:'20px',
                                    opacity: 1,
                                    float:'right',
                                    height: '67px',
                                    width: '350px'}}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Save
                        </span>
                        </Button></Col>
                </Row>
                        <Row>
                            <Col>
                                <br/> <br/>
                            </Col>
                        </Row>
                    </Form></Col>
                </Row>

            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default SessionDetail;
