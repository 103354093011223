import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {ResponseLevel, ResponsePrasentationType, ResponseSession,ResponseAbstractSingle} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import Badge from "react-bootstrap/Badge";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import Authors from "../helper/authors";
import Affiliations from "../helper/affiliation";
import Keywords from "../helper/keywords";
import {InfoButton, UniForm} from "../../../../../@WUM/core/Elements";
import {IntlMessage, IntlMessageValue, MIcon} from "../../../../../@WUM/core/Elements/index";
import SunEditor from "suneditor-react";
import {ReplaceHtmlEntities} from "../../../../../@WUM/core/Function/index"
import send from "../elements/Send";
import {SecureRequest} from "../../../SessionforHost/modules/Pages/detail";
import React, {useState} from "react";

const AbstractDetail = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    const [Author, setAuthor] = useState(0)
    const [Keyword, setKeyword] = useState(0)
    const [Affiliate, setAffiliate] = useState(0)
    const Level = ResponseLevel();
    const response = ResponseAbstractSingle(id,setAuthor,setKeyword,setAffiliate)
    const PresentationType = ResponsePrasentationType();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)
    const Session = ResponseSession();
    let authorint = Author;
    let keywordsint = Keyword;
    let affiliationsint = Affiliate;
    const SendForm = (e:any,state:any) => {
        e.preventDefault();
        let data = {
            state:state,
            response: (document.getElementById('Response') as HTMLTextAreaElement).value
        };
        let root = ApiURL();
        let raw = JSON.stringify(data);
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };


        fetch(root+"Conference/Presentations/icy/response/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
    }

    const afterSend = (state:any) => {
        if(state === 'success') {
            Notification({reload:true,type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
        } else if(state === 'error'){
            Notification({reload:true,type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})
        }
    }

    let authorIconInt = (response.length > 0 ? response[0].authors.length + 1 : 0);
    let keywordIconInt = (response.length > 0 ? response[0].tags.length + 1 : 0);
    const deleteElement = (e:any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }
    const addElement = (row:any = false,col:any,id:any,addClass:any='',numberIcon:any=false) => {
        let counter;
        if(id==='Authors') {
            authorint++;
            counter= authorint;
        }
        if(id==='Keywords'){
            if(keywordsint ===4) {
                alert('You may not enter more than 5 keywords')
                return (<></>)
            }
            keywordsint++;
            counter= keywordsint;
        }
        let preHolderAffiliations = <></>;
        if(id==='Affiliations') {
            affiliationsint++;
            counter= affiliationsint;
            let preHolderAffiliationsAuthorOption = [];

            for(let pre =1;pre<authorIconInt;pre++) {
                preHolderAffiliationsAuthorOption.push(<option value={pre}>{pre}</option>)
            }

            preHolderAffiliations = <><InputGroup.Text>
                Author:
            </InputGroup.Text>
                <Form.Select className={"col-1 AffiliationAuthor"} aria-label="Default select example" name={'affiliationText'+counter} id={'AffiliationAuthor'}  required={true}>
                    {preHolderAffiliationsAuthorOption}
                </Form.Select>
                <InputGroup.Text>
                    Affiliations:
                </InputGroup.Text></>
        }
        let numberIconHolder = <></>;
        if(numberIcon !== false) {
            numberIconHolder = <InputGroup.Text >
                {(id==='Keywords' ? keywordIconInt : (id==='Authors' ? authorIconInt : 0))}
            </InputGroup.Text>

            if(id==='Keywords') {
                keywordIconInt++;
            }
            if(id==='Authors') {
                let select = document.getElementsByClassName('AffiliationAuthor') as HTMLSelectElement;
                for(let s = 0;s<select.length;s++){
                    var opt = document.createElement('option');
                    opt.value = authorIconInt.toString();
                    opt.innerHTML = authorIconInt.toString();
                    select[s].appendChild(opt);
                }

                authorIconInt++;
            }
        }

        if(row === true) {
            // Affiliatons
            let newElement = (<><div className={'col-'+col}><InputGroup
                className={"mb-3 inputGroupExtra form-control " + addClass}>
                {numberIconHolder}
                {preHolderAffiliations}
                <Form.Control
                    maxLength={250}
                    name={id + '' + counter}
                    placeholder={id}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={() => addElement(row, col, id, addClass, numberIcon)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                </InputGroup.Text>
            </InputGroup></div>
            </>);
            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            // Author
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                {numberIconHolder}
                {preHolderAffiliations}
                <Form.Control
                    maxLength={200}
                    name={id+''+counter}
                    placeholder={id}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass,numberIcon)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-'+col)
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        }

    }


    if(Level.length > 0 && PresentationType.length > 0 && Session.length > 0 && response.length > 0) {
        let optionLevel = [];

        let optionPresentationType = [];

        let optionSession = [];

        for(let l=0;l<Level.length;l++){
            optionLevel.push(
                <>
                    <option value={Level[l].id}>{Level[l].type}</option>
                </>
            )
        }

        for(let l=0;l<PresentationType.length;l++){
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id}>{PresentationType[l].type}</option>
                </>
            )
        }

        for(let l=0;l<Session.length;l++){
            optionSession.push(
                <>
                    <option value={Session[l].id}
                            dangerouslySetInnerHTML={{__html: Session[l].title}}/>
                </>
            )
        }


        let badgeColor;
        let badgeText;
        let state = [
            {name: 'resubmitted',color:'danger'},
            {name: 'submitted',color:'danger'},
            {name: 'Icymare: Declined ',color:'danger'},
            {name: 'Icymare: Rework by user',color:'warning'},
            {name: 'Icymare: Passed to Host',color:'warning'},
            {name: 'Host: Not suitable for session',color:'danger'},
            {name: 'Host: Revision by user',color:'warning'},
            {name: 'Host: Final acceptance',color:'success'}]

        for(let s =0;s<state.length;s++) {
            if(state[s].name === response[0].state){
                badgeColor = state[s].color;
                badgeText = state[s].name;
            }
        }

        const SunEditorConfig = () => {
            return [
                // Default
                ['undo', 'redo','italic','subscript', 'superscript','removeFormat','fullScreen'],
            ]
        }


        return (<>
            <Row className={''}>
                <Row>
                    <Col xs={12} md={12} xl={4} style={{
                        background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',
                        color: 'white',
                        maxHeight: '840px'
                    }}>
                        <br/>
                        <p className={'text-center d-none d-xl-block'}><img
                            src={'https://cdn.wum-solution.de//icymareKonfDev/2024/02/27/Xk/ICYMARE-Logo-wide-screen-SW.png'}/>
                        </p>
                        <br/>
                        <h2>Abstract Submission ICYMARE 2024 Bremen</h2>
                        <p><img
                            src={"https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/R5/ICYMARE-2024-BREMEN-CallForAbstracts.jpg"}/>
                        </p>
                        <br/>
                        <p><IntlMessage messageId="icy.abstract.submission" Store={Store}/></p>
                        <p className={'text-center'}>State: <Badge bg={badgeColor}> {badgeText}
                        </Badge></p>
                    </Col>
                    <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e,afterSend,id,response[0].file1,response[0].file2)}>
                        <Row> <h3>Presenting Author</h3></Row><Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Title">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.title', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.title.explanation', Store)
                                    }} />
                                <Form.Control type="text" name={'Title'} placeholder="Enter Title" readOnly={false} defaultValue={response[0].title}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="FirstName">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.first.name', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.first.name.explanation', Store)
                                    }} />
                                <Form.Control type="text" name={'FirstName'} placeholder="Enter first name" readOnly={false} defaultValue={response[0].user.first_name}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="LastName">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.last.name', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.last.name.explanation', Store)
                                    }} />
                                <Form.Control type="text" name={'LastName'} placeholder="Enter last name" readOnly={false} defaultValue={response[0].user.last_name}/>
                            </Form.Group>
                        </Col>
                    </Row>
                        <Row>

                            <Col>
                                <Form.Group className="mb-3" controlId="Emailaddress">
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.email', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.email.explanation', Store)
                                        }} />
                                    <Form.Control type="email" name={'Emailaddress'} placeholder="Enter email adress" readOnly={false} defaultValue={response[0].user.email}/>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                            <Form.Label>Main affiliation</Form.Label>
                            <Form.Control type="text" name={'Mainaffiliation'} readOnly={false} placeholder="Enter main affiliation" defaultValue={response[0].mainAffiliation} />
                        </Form.Group></Col></Row>


                        <Row>
                            <h3><IntlMessage messageId="icy.education.level" Store={Store} /></h3>
                            <p><IntlMessage messageId="icy.education.level.explanation" Store={Store} /></p>
                        </Row>

                        <Row>
                            <Col>
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.level', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.level.explanation', Store)
                                    }} />
                                <Form.Select aria-label="Default select example" name={'Level'}  disabled={true} defaultValue={
                                    (response[0].educationLevel !== null ? response[0].educationLevel.levelId : '')}>
                                    {optionLevel}
                                </Form.Select>
                            </Col>
                            <Col><Form.Group className="mb-3" controlId="Year">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.year', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.edu.year.explanation', Store)
                                    }} />
                                <Form.Control type="text"  name={'Year'} placeholder="Enter Year" readOnly={false} defaultValue={
                                    (response[0].educationLevel !== null ? response[0].educationLevel.year : '')}/>
                            </Form.Group></Col>
                        </Row>

                        <Row>
                            <h3><IntlMessage messageId="icy.sessions" Store={Store} /></h3>
                            <p><IntlMessage messageId="icy.sessions.explanation" Store={Store} /></p>
                        </Row>
                        <Row>
                            <Col>
                                <UniForm.Label
                                text={IntlMessageValue('de', 'icy.session', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.session.explanation', Store)
                                }} />
                                <Form.Select aria-label="Default select example" name={'Sessions'} defaultValue={response[0].confSession.id}>
                                    {optionSession}
                                </Form.Select>
                            </Col>
                            <Col>
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.type.presentation', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.type.presentation.explanation', Store)
                                    }} />
                                <Form.Select aria-label="Default select example" name={'PresentationType'} disabled={true} defaultValue={response[0].presentationTypeId}>
                                    {optionPresentationType}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Group className="mb-3" controlId="PresentationTitle">
                                <Form.Label>Presentation title</Form.Label>
                                <SunEditor
                                    key={'test'}
                                    name={'PresentationTitle'}
                                    placeholder="Enter presentation title"
                                    defaultValue={response[0].presentationTitle}
                                    readOnly={false}
                                    hideToolbar={true}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }

                                    }}
                                    height={'50px'}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <h3><IntlMessage messageId="icy.authors.affiliation" Store={Store}/></h3>
                            <Col xs={11}>
                                <p><IntlMessage messageId="icy.authors.affiliation.explanation" Store={Store}/></p>
                            </Col>
                            <Col>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.author.name.explanation', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                        </Row>
                        <Authors author={response[0].authors} mode={'icymare'} addElement={addElement}/>
                        <br />
                        <Row>
                            <Col xs={11}>
                                <p><IntlMessage messageId="icy.affiliation.text" Store={Store} /></p>
                            </Col>
                            <Col>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.affiliation.explanation', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                        </Row>
                        <Affiliations affiliations={response[0].affiliates} mode={'icymare'} addElement={addElement} />
                        <br />
                        <br />
                        <Row>
                            <h3><IntlMessage messageId="icy.social.media" Store={Store}/></h3>
                            <p><IntlMessage messageId="icy.social.media.explanation" Store={Store}/></p>
                        </Row>
                        <Row>
                            <Col>
                            <Form.Group className="mb-3" controlId="Twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text"  name={'Twitter'} readOnly={false} placeholder="Enter Twitter account" defaultValue={response[0].socialMediaInstitution[0].Twitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Instagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'Instagram'} readOnly={false} placeholder="Enter Instagram account" defaultValue={response[0].socialMediaInstitution[0].Instagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Facebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'Facebook'} readOnly={false} placeholder="Enter Facebook account" defaultValue={response[0].socialMediaInstitution[0].Facebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="LinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'LinkedIn'} readOnly={false} placeholder="Enter LinkedIn account" defaultValue={response[0].socialMediaInstitution[0].LinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Mastodon">
                                    <Form.Label>Mastodon</Form.Label>
                                    <Form.Control type="text" name={'Mastodon'} readOnly={false} placeholder="Enter Mastodon account" defaultValue={response[0].socialMediaInstitution[0].Mastodon}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Bluesky">
                                    <Form.Label>Bluesky</Form.Label>
                                    <Form.Control type="text" name={'Bluesky'} readOnly={false} placeholder="Enter Bluesky account" defaultValue={response[0].socialMediaInstitution[0].Bluesky}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <h4><IntlMessage messageId="icy.social.media.institution" Store={Store} /></h4>
                            <p><IntlMessage messageId="icy.social.media.explanation.institution" Store={Store} /></p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" name={'InstitutionTwitter'} readOnly={false} placeholder="Enter Twitter account" defaultValue={response[0].socialMediaInstitution[0].InstitutionTwitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'InstitutionInstagram'} readOnly={false} placeholder="Enter Instagram account" defaultValue={response[0].socialMediaInstitution[0].InstitutionInstagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'InstitutionFacebook'} readOnly={false} placeholder="Enter Facebook account" defaultValue={response[0].socialMediaInstitution[0].InstitutionFacebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'InstitutionLinkedIn'} readOnly={false} placeholder="Enter LinkedIn account" defaultValue={response[0].socialMediaInstitution[0].InstitutionLinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionMastodon">
                                    <Form.Label>Mastodon</Form.Label>
                                    <Form.Control type="text" name={'InstitutionMastodon'} readOnly={false} placeholder="Enter Mastodon account" defaultValue={response[0].socialMediaInstitution[0].InstitutionMastodon}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionBluesky">
                                    <Form.Label>Bluesky</Form.Label>
                                    <Form.Control type="text" name={'InstitutionBluesky'} readOnly={false} placeholder="Enter Bluesky account" defaultValue={response[0].socialMediaInstitution[0].InstitutionBluesky}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <h3><IntlMessage messageId="icy.keywords" Store={Store} /></h3>
                            <p><IntlMessage messageId="icy.keywords.explanation" Store={Store} /></p>
                        </Row>
                        <Keywords keywords={response[0].tags} mode={'icymare'} addElement={addElement} />

                        <Row>
                            <Col xs="auto">
                                <h3><IntlMessage messageId="icy.abstract" Store={Store}/></h3>
                            </Col>
                            <Col>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.abstract.support', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                            <p><IntlMessage messageId="icy.abstract.explanation" Store={Store}/></p>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="Abstract">
                                <SunEditor
                                    key={'test'}
                                    name={'Abstract'}
                                    placeholder="Enter Abstract"
                                    readOnly={false}
                                    defaultValue={response[0].abstract}
                                    hideToolbar={true}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'250px'}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.CV', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.abstract.CV.explanation', Store)
                                        }} />
                                    <Form.Control id={'fileuploadAttachment1'} name={'file1'} type="file" accept=".pdf"/>
                                </Form.Group>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.abstract.upload', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.abstract.docxDocument.explanation', Store)
                                        }} />
                                    <Form.Control id={'fileuploadAttachment2'} name={'file2'} type="file" accept=".docx"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {response[0].media1 !== null && response[0].media1 !== undefined
                                ?
                                <Col>
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.CV', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.abstract.CV.explanation', Store)
                                        }} />
                                    <br/>
                                    <object
                                        data={SecureRequest(response[0].media1.cdn_url)}
                                        width={'100%'}
                                        height={'400px'}
                                        aria-label={'pdf'}
                                        className={'mb-3'}
                                    />
                                </Col>
                                :
                                <>
                                </>

                            }
                            {response[0].media2 !== null && response[0].media2 !== undefined
                                ?
                                <Col className={"mb-3"}>
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.abstract.upload', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.abstract.docxDocument.explanation', Store)
                                        }} />
                                    <Form.Label  className={'h-100 w-100'}>
                                        <a download={response[0].media2.name}
                                           href={SecureRequest(response[0].media2.cdn_url) + '&content_type=' + response[0].media2.content_type + '&filename=' + response[0].media2.name}
                                           target={'_blank'} style={{height: "400px"}}
                                           className={'w-100 d-flex align-items-center text-center btn btn-primary'}>
                                            <div className={'w-100 display-6'}>
                                                <div><MIcon children={'description'} variant={'-outlined'} className={'display-3'}/></div>
                                                Abstract as .docx
                                            </div>
                                        </a>
                                    </Form.Label>
                                </Col>
                                :
                                <>
                                </>

                            }
                        </Row>
                        <Row>
                            <Col xs="8">
                                <Button variant="success col-12" type="submit"><IntlMessage messageId="icy.abstract.submitFile" Store={Store} /></Button>
                            </Col>
                            <Col className={"my-auto"}>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.abstract.exchangeFile.explanation', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br/>
                                <Form.Group className="mb-3" controlId="mending">
                                    <Form.Label>Mending Message</Form.Label>
                                    <Form.Control as="textarea" name={'Response'} id={'Response'}  rows={5} defaultValue={response[0].icyResponse}/>
                                </Form.Group>
                                <br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'text-center'}>{back}</Col>
                            <Col className={'text-center'}><Button variant="danger" onClick={(e)=>SendForm(e,'1')}>Declined</Button></Col>
                            <Col className={'text-center'}><Button variant="warning" onClick={(e)=>SendForm(e,'2')}>Rework by user</Button></Col>
                            <Col className={'text-center'}><Button variant="success" onClick={(e)=>SendForm(e,'3')}>Passed to Host</Button></Col>

                        </Row>
                        <Row>
                            <Col>
                                <br/> <br/>
                            </Col>
                        </Row>
                    </Form></Col>
                </Row>

            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default AbstractDetail;
