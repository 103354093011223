import {CMSRouteConfig} from "../modules/CMS";
import {TicketPagesConfig} from "../modules/ticketsystem/modules";
import {SettingsRouteConfig} from "../modules/setting";
import {useSelector} from "react-redux";
import {SessionHostPagesConfig} from "../modules/CMS/SessionforHost";
import {ProfilPagesConfig} from "../modules/CMS/profil";
import {RegistrationConferenceConfig} from "../modules/CMS/registrationConference"
import {SurveyConferenceConfig} from "../modules/CMS/Survey";
import {StatisticsConfig} from "../modules/CMS/statistics";


export const RegisterRoute = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch(Role){
        case 'user':
            return[
                ...CMSRouteConfig(value),
                ...SessionHostPagesConfig(value),
                ...RegistrationConferenceConfig(value),
                ...ProfilPagesConfig(value),
                ...SurveyConferenceConfig(value)
            ]
        case 'host':
            return[
                ...CMSRouteConfig(value),
                ...SessionHostPagesConfig(value),
                ...RegistrationConferenceConfig(value),
                ...ProfilPagesConfig(value),
                ...SurveyConferenceConfig(value)
            ]
        case 'icymare':
            return[
                ...CMSRouteConfig(value),
                ...TicketPagesConfig(value),
                ...RegistrationConferenceConfig(value),
                ...SettingsRouteConfig(value),
                ...SessionHostPagesConfig(value),
                ...ProfilPagesConfig(value),
                ...SurveyConferenceConfig(value),
                ...StatisticsConfig(value)
            ]
        case 'superadmin':
            return[
                ...CMSRouteConfig(value),
                ...TicketPagesConfig(value),
                ...RegistrationConferenceConfig(value),
                ...SettingsRouteConfig(value),
                ...SessionHostPagesConfig(value),
                ...ProfilPagesConfig(value),
                ...SurveyConferenceConfig(value),
                ...StatisticsConfig(value)
            ]
        default:
            return[
                ...CMSRouteConfig(value),
                ...SessionHostPagesConfig(value),
                ...RegistrationConferenceConfig(value),
                ...ProfilPagesConfig(value)
            ]
    }


}
