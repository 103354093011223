import React, {useState
} from 'react';
import {useNavigate,
    useParams
} from 'react-router-dom';
import { ContentSwitch, IntlMessage, IntlMessageValue, SearchJSX, SpinnerJSX } from '../../../../@WUM/core/Elements/index';
import {useSelector} from "react-redux";
import configuration from './configuration/Page/overview';
import {ResponseAbstractsOverviewAll} from "../API/Response/profil";
import PielersTable from "../../../../@WUM/core/tablemaker";

const OverviewAbstracts = (value:any) => {

    const {page,deleteID} = useParams<{page: string,deleteID: string}>();
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }

    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        history(-1);
    };

    const id = 1

    const year = 2024
    const offset = 999
    const pagex = 1

    if(deleteID) {
        routeChange('/Abstract/overview/1')
    }
    const debug = (page: string | undefined,modalShow: boolean, modalData: any[]) => {
        return;
    }
    const destination = 'Abstract';
    const namespace_search = "Abstract_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const [search, setSearch] = useState<boolean>(false)
    let response = ResponseAbstractsOverviewAll(year,offset,pagex);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    debug(page,modalShow,modalData)
    const [mode, setMode] = useState('cart');

    const SearchConfig = [{
        namespace:namespace_search,
        destination: destination,
        input:[
            {id:'search_name',translation: 'Search for title...',param: 'presentationTitle',method: 'like'}

        ],
        button: ['Search','Delete Search','Submit Abstract']
    }]


    const CardsConfiguration = {
        'data': {
            'ressource': response,
            'img': '',
            'status': 'state',
            'statusindi': true,
            'statusMapping': [
                {name: 'resubmitted',color:'dark'},
                {name: 'submitted',color:'danger'},
                {name: 'Icymare: Declined ',color:'danger'},
                {name: 'Icymare: Rework by user',color:'warning'},
                {name: 'Icymare: Passed to Host',color:'warning'},
                {name: 'Host: Not suitable for session',color:'danger'},
                {name: 'Host: Revision by user',color:'warning'},
                {name: 'Host: Final acceptance',color:'success'}
            ],
            'link': 'translation.de.slug',
            'prelink': '/magazin/[param]/',
            'prelinksearch': 'translation.de.slug',
            'title': 'presentationTitle',
            'descriptionShow': true,
            'description': 'confSession.title',
            'router': 'id',
            'showauthor': true,
            'author_firstname': 'user.first_name',
            'author_lastname': 'user.last_name',
            'preroute': '/Abstract/Detail/',
            'edit': 'Show Abstract',
            'editOnly': true,
            'delete': false,
            'deleteLink': '/Abstract/Delete/'+page+'/[ID]',
            'Dontvisited': true,
        }
    }


    // console.log(response[1])

    const [icyStateCountData, setIcyStateCountData] = useState({}); // Initialize icyStateCountData state

    interface GroupedCounts {
        [key: string]: number;
    }

    const groupedCounts = response.filter(
        (item) => item.state === "Host: Final acceptance"
    ).reduce((acc, item) => {
        const sessionTitle = item.confSession.title;
        const presentationType = item.presentationType?.type || 'Unknown';

        // Erstelle einen eindeutigen Schlüssel für die Gruppierung
        const groupKey = `${sessionTitle}-${presentationType}`;

        // Erhöhe den Zähler für die entsprechende Gruppe
        acc[groupKey] = (acc[groupKey] || 0) + 1;
        return acc;
    }, {} as { [key: string]: number });





    if (response.length > 0) {

        let TotalAccepted = response.filter((item) => item.state === "Host: Final acceptance").length


        // const filtered = response.filter(item =>
        //     item.name.includes(keyword) || item.description.includes(keyword)
        // );
        return (
            <>
                {/*<SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig}/>*/}
                <p>Number of entries with icyState=3: {TotalAccepted}</p>

                <table className="table">
                    <thead>
                    <tr>
                        <th>Session Title</th>
                        <th>Presentation Type</th>
                        <th>Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(groupedCounts).map(([groupKey, count]) => {
                        const [sessionTitle, presentationType] = groupKey.split('-');

                        return (
                            <tr key={groupKey}>
                                <td>{sessionTitle}</td>
                                <td>{presentationType}</td>
                                <td>{count}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                <h2>Detailed data</h2>
                <div className={"icyTable"}>
                    <PielersTable Configuration={configuration(response)}/>
                </div>
                {/*{<ContentSwitch pagenew={'new'} destinationnew={'Abstract'} tonew={'new'} text={'Show Abstract'}*/}
                {/*                response={response} CardsConfiguration={CardsConfiguration} mode={mode}*/}
                {/*                Configuration={configuration(response)} setMode={setMode} destination={destination}*/}
                {/*                page={page}*/}
                {/*                to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}*/}
                {/*                modalShow={modalShow} setModalShow={setModalShow} modalData={modalData}*/}
                {/*                setModalData={setModalData} SearchParam={'?translation.title'}*/}
                {/*                searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true}*/}
                {/*                stop={false} Store={Store}/>}*/}

            </>

        )
    } else {
        localStorage.setItem('searchEnterInput', '');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }

};


export default OverviewAbstracts;